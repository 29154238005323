import { ADD_POST, REMOVE_POST, ADD_POSTS, REMOVE_POSTS } from '../constants/blog';

const initialState = {
	posts: [],
	postsFetched: false,
	post: {},
	postFetched: false,
};

export default function blog (state = initialState, action = {}) {
	const { type, payload } = action;
	
	switch (type) {
	case ADD_POSTS:
		return { ...state, posts: payload };
	case ADD_POST:
		return {
			...state,
			post: payload,
			postFetched: true,
		};
	case REMOVE_POSTS:
	case REMOVE_POST:
		return initialState;
	
	default:
		return state;
	}
}
