import React from 'react';

function FileInput (props) {
    const { meta = {}, input = {}, label } = props;


    function changeFile(e) {
        e.preventDefault();
        input.onChange(e.target.files[0]);
    }

    return (
        <label htmlFor='document'>
            Upload a file
            <input onChange={changeFile} id='document' type="file" style={{ display: 'none' }} />
            {meta.error && meta.touched && <span className="error_msg">  {meta.error}</span>}
        </label>
    );
}

export default FileInput;

