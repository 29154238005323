import axios from 'axios';
import store from './clienStore';
import { LOG_OUT } from 'constants/auth';

const instance = axios.create({
	baseURL: process.env.REACT_APP_REST_API_ENDPOINT,
	validateStatus: (status) => {
		if (status === 401) {
			store.dispatch({ type: LOG_OUT });
		}
		return true;
	},
});

// Alter defaults after instance has been created
export default instance;
