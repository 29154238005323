import React, { Component } from 'react';
import withOutSideRef from '../WithOutsideRef';

import './styles.scss';
import imgDownArrow from 'resources/images/down-arrow.png';

class DropDown extends Component {
    state = { toggle: false };

    componentWillReceiveProps(nextProps) {
        if (this.state.toggle !== nextProps.toggle) {
            this.setState({ toggle: nextProps.toggle });
        }
    }

    handleToggle = () => {
        this.setState(prevState => ({
            toggle: !prevState.toggle
        }));
    };

    render() {
        const { menuItems, wrapperRef, user } = this.props;
        const { toggle } = this.state;

        return (
            <div className="dropdown-container" ref={wrapperRef}>
                <div className="dropdown-toggle" onClick={this.handleToggle}>
                    <span>Hello, {user.firstName}</span>
                    <img src={imgDownArrow} alt="down-arrow" />
                </div>
                {toggle && (
                    <div className="dropdown-list">
                        {menuItems &&
                        menuItems.map((item, index) => (
                            <div
                                className="dropdown-list__item"
                                key={index}
                                onClick={() => {
                                    item.action && item.action();
                                    this.handleToggle();
                                }}
                            >
                                <span>{item.label}</span>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        );
    }
}

export default withOutSideRef(DropDown);
