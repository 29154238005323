import React from 'react';
import GooglePlay from 'resources/images/google-play.svg';
import './styles.scss';


const download = (e) => {
	// Manually push the event to the data layer, ensuring the data-tracking attribute is included
	window.dataLayer.push({
		event: 'downloadClick',
		'gtm.element': e.currentTarget, // This ensures the data layer captures the element with the data-tracking attribute
		'gtm.elementClasses': e.currentTarget.className,
		'gtm.elementId': e.currentTarget.id,
		'gtm.elementDataTracking': e.currentTarget.getAttribute('data-tracking')
	});
	// Your existing download logic
	window.open('https://play.google.com/store/apps/details?id=com.scanoma.app', '_blank');
};



export default () => {

	return (
		<div className='app-link headerBtn yellow' onClick={download} data-tracking="downloadAppAndroid">
			<img src={GooglePlay} alt='google-play' />
			<div className='app-link-title'>
				<div className='sub-header'>Get it on</div>
				<div className='header'>Google Play</div>
			</div>
		</div>
	);
}
