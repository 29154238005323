import React from 'react';
import AppleIcon from 'resources/images/apple.svg';
import './styles.scss';


const download = (e) => {
	// Manually push the event to the data layer, ensuring the data-tracking attribute is included
	window.dataLayer.push({
		event: 'downloadClick',
		'gtm.element': e.currentTarget, // This ensures the data layer captures the element with the data-tracking attribute
		'gtm.elementClasses': e.currentTarget.className,
		'gtm.elementId': e.currentTarget.id,
		'gtm.elementDataTracking': e.currentTarget.getAttribute('data-tracking')
	});
	// Your existing download logic
	window.open('https://apps.apple.com/au/app/scanoma-mole-check/id1478978663', '_blank');
};


export default () => {


	return (
		<div className='app-link headerBtn' onClick={download} data-tracking="downloadAppIos">
			<img src={AppleIcon} alt='apple' />
			<div className='app-link-title'>
				<div className='sub-header'>Download at the</div>
				<div className='header'>App Store</div>
			</div>
		</div>
	);
}