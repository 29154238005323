import { SubmissionError } from 'redux-form';
import axios from '../requestConfig';
import _ from 'lodash';
import { SIGN_UP_REQUEST_SUCCESS, LOG_OUT, SET_AVATAR } from 'constants/auth';
import { fileToBase64 } from 'utilities';
import errors from 'utilities/errors';

export function login (values) {
	return (dispatch) => {
		return axios.post('/auth/login', values).then((resp) => {
			if (resp.status === 401) {
				throw new SubmissionError({
					_error: errors[resp.data.message] || 'Login or password is incorrect',
				});
			}
			
			const payload = _.get(resp, 'data.data');
			if (payload) {
				axios.defaults.headers.common.Authorization = `Bearer ${payload.token}`;
				
				dispatch({
					type: SIGN_UP_REQUEST_SUCCESS,
					payload: _.get(resp, 'data.data')
				});
			} else {
				throw new SubmissionError({
					_error: 'Service temporary unavailable try again in a few minutes.',
				});
			}
		});
	};
}

export function register (values) {
	let data = values;
	return async () => {
		if (values.file) {
			let fileArray = {
				extension: values.file.name.split('.')[1],
			};
			const fileB64 = await fileToBase64(values.file);
			data = { ...data, file: { ...fileArray, data: fileB64 } };
		}
		
		return axios.post('/auth/register', data).then((resp) => {
			if (resp.status === 400) {
				throw new SubmissionError({
					email: 'Email already exist'
				});
			}
		});
	};
}

export function contactUs (data) {
	return axios.post(`/auth/contact-us`, data).then((resp) => {
		if (resp.status !== 200) {
			throw new SubmissionError({
				_error: 'Service temporary unavailable'
			});
		}
	});
}

export function changePassword (data) {
	return axios.post(`/auth/change-password`, data).then((resp) => {
		if (resp.status !== 200) {
			throw new SubmissionError({
				_error: 'Your old password is not correct.'
			});
		}
	});
}


export const restorePassword = async (token, password) => {
	const response = await axios.post(`/auth/new-password/${token}`, { newPassword: password });
  
	if (response.status >= 400) {
	  throw new Error(response.data.message || 'An error occurred');
	}
  
	return response;
  };
  
  
  
  
  

export function uploadPhoto (avatar) {
	return (dispatch) => {
		return axios.post(`/auth/avatar`, { avatar }).then((resp) => {
			if (resp.status !== 200) {
				throw new Error('error');
			}
			dispatch({
				type: SET_AVATAR,
				payload: resp.data.avatar,
			});
		});
	};
}

export function forgotPassword (data) {
	return axios.post('/auth/new-password', data).then((resp) => {
		if (resp.status !== 200) {
			throw new SubmissionError({
				_error: 'Email does not exist'
			});
		}
	});
}

export function getPayment (data) {
	return axios.get('/auth/payment', data);
}

export function setPayment (data) {
	return axios.post(`/auth/payment`, data).then((resp) => {
		if (resp.status !== 200) {
			throw new SubmissionError({
				_error: 'Email does not exist'
			});
		}
	});
}

export function deletePayment () {
	return axios.delete('/auth/payment');
}

export function logout () {
	return (dispatch) => {
		dispatch({ type: LOG_OUT });
	};
}
