import React from "react";
import mediumZoom from "medium-zoom";
import ImageZoom from "../utilities/ImageZoom";

export default function PrivateImage({ className, alt, src }) {
  const url = `${process.env.REACT_APP_BUCKET_ENDPOINT}/${src}`;
  const zoom = React.useRef(mediumZoom());

  if (src) {
    return (
      <ImageZoom
        src={url}
        alt={alt}
        className={className}
        zoom={zoom.current}
        background="black"
      />
    );
  }
  return null;
}

export function privateLink(src) {
  return `${process.env.REACT_APP_BUCKET_ENDPOINT}/${src}`;
}
