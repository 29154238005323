import React from 'react';
import ReactDOM from 'react-dom';
import Loadable from 'react-loadable';
import { Provider as ReduxProvider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import './index.scss';
import App from './App';
import { unregister } from './registerServiceWorker';
import store from './clienStore';
import instance from './requestConfig';

const tagManagerArgs = {
	gtmId: 'GTM-MJJSP53'
};

TagManager.initialize(tagManagerArgs);

const token = store.getState()?.auth?.token;


instance.defaults.headers.common.Authorization = `Bearer ${token}`;

const AppBundle = (
	<ReduxProvider store={store}>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</ReduxProvider>
);

try {
	Loadable.preloadReady().then(() => {
		ReactDOM.hydrate(
			AppBundle,
			document.getElementById('root')
		);
	});
} catch (e) {
	window.location.replace('/');
}

unregister();
