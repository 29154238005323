import React from 'react';
import { Container, Columns } from 'react-bulma-components';
import './styles.scss';
import Curia from './Curia';
import Microsoft from './Microsoft';

export default () => {
	return (
		<Container id='partners'>
			<h3 className='title'>
				<p>
					Scanoma <span className='blue font-italic'>Partners</span>
				</p>
			</h3>
			<Columns className='is-vcentered is-multiline'>
				<Columns.Column className='is-12-mobile is-half-tablet is-flex' >
					<Curia />
				</Columns.Column>
				<Columns.Column className='is-12-mobile is-half-tablet is-flex'>
					<Microsoft />
				</Columns.Column>
			</Columns>
		</Container>
	);
}
