import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import menu from 'resources/images/hamburger_menu_icon.svg';
import withOutSideRef from '../WithOutsideRef';
import classNames from 'classnames';

import './styles.scss';

class DropDown extends Component {
    state = { toggle: false };

    componentWillReceiveProps(nextProps) {
        if (this.state.toggle !== nextProps.toggle) {
            this.setState({ toggle: nextProps.toggle });
        }
    }

    handleToggle = () => {
        this.setState(prevState => ({
            toggle: !prevState.toggle
        }));
    };

    render() {
        const { wrapperRef, token, toggleLogin, toggleSignUp, logout } = this.props;
        const { toggle } = this.state;

        return (
            <div className="dropdown-container" ref={wrapperRef}>
                <div className="dropdown-toggle" onClick={this.handleToggle}>
                    <img className='drop-down-button' alt="menu" src={menu} />
                </div>
                {toggle && (
                    <div className={classNames('dropdown-list', { wide: token })}>
                         <div className="dropdown-list__item">
                            <Link to="blog" className="navMenu">
                                BLOG
                            </Link>
                        </div>
                        <div className="dropdown-list__item">
                            <Link to="contact" className="navMenu">
                                CONTACT US
                            </Link>
                        </div>

                        {token ? (
                            <Fragment>
                                <div className="dropdown-list__item">
                                    <Link to="requests" className="navMenu">
                                        DIAGNOSiS REQUESTS
                                    </Link>
                                </div>
                                <div className="dropdown-list__item">
                                    <Link to="/profile" className="navMenu">
                                        SETTINGS
                                    </Link>
                                </div>
                                <div className="dropdown-list__item">
                                    <button onClick={logout} className="navMenu">
                                        SIGN OUT
                                    </button>
                                </div>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <div className="dropdown-list__item">
                                    <button className="navMenu" onClick={toggleLogin}>
                                        LOGIN
                                    </button>
                                </div>
                                <div className="dropdown-list__item">
                                    <button className="navMenu" onClick={toggleSignUp}>
                                        SIGN UP
                                    </button>
                                </div>
                            </Fragment>
                        )}

                    </div>
                )}
            </div>
        );
    }
}

export default withOutSideRef(DropDown);
