import React, { useState, Fragment } from 'react';
import './styles.scss';
import { connect } from 'react-redux';
import { useSelector } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { Level } from 'react-bulma-components';
import DropDown from './DropDown';
import MobileDropDown from './MobileDropDown';
import AvatarImage from 'resources/images/avatar.png';

import { logout } from 'actions/auth';
import { closeSignUp, openSignUp } from 'actions/ui';
import Login from './Login';
import Signup from './Signup';
import SignupProceed from './SignupProceed';
import ResetPassword from './ResetPassword';
import Logo from './Logo';
import PrivateImage from '../PrivateImage';

const TOGGLE_NONE = 0;
const TOGGLE_LOGIN = 1;
const TOGGLE_SIGNUP = 2;
const TOGGLE_SIGNUP_PROCEED = 3;
const TOGGLE_RESET_PASSWORD = 4;

function Navbar(props) {
    const { history, logout } = props;
    const user = useSelector((state) => state.auth);
    const [toggleID, setToggleId] = useState(TOGGLE_NONE);

    const isOpen = (id) => toggleID === id;
    const toggleModal = (id) => setToggleId(id);

    return (
        <Level className="navbar-container">
            <Level.Side align="left">
                <Level.Item className="forpc">
                    <Logo />
                </Level.Item>
                <Level.Item className="mobile">
                    <MobileDropDown
                        token={user.token}
                        toggleLogin={() => toggleModal(TOGGLE_LOGIN)}
                        toggleSignUp={() => toggleModal(TOGGLE_SIGNUP)}
                        logout={logout}
                    />
                </Level.Item>
            </Level.Side>
            <Level.Side align="right" className="logo-margin-top-remove">
                 <Level.Item className="topmenu">
                    <Link to="/blog" className="navMenu">
                        BLOG
                    </Link>
                </Level.Item>
                <Level.Item className="topmenu">
                    <Link to="/contact" className="navMenu">
                        CONTACT US
                    </Link>
                </Level.Item>
                {user.token ? (
                    <Fragment>
                        <Level.Item className="topmenu enabled">
                            <Link to="/requests" className="navMenu">
                                DIAGNOSIS REQUESTS
                            </Link>
                        </Level.Item>
                        <Level.Item className="topmenu profile">
                            <DropDown
                                user={user}
                                menuItems={[
                                    {
                                        label: 'SETTINGS',
                                        action: () => history.push('/profile')
                                    },
                                    {
                                        label: 'SIGN OUT',
                                        action: logout
                                    }
                                ]}
                            />
                            {
                                user.avatar ? <PrivateImage className="avatar doctor-avatar" src={user.avatar} /> : <img className="avatar" alt="avatar" src={AvatarImage} />
                            }
                        </Level.Item>
                    </Fragment>
                ) : (
                        <Fragment>
                            <Level.Item className="topmenu enabled">
                                <button className="navMenu" onClick={() => toggleModal(TOGGLE_LOGIN)}>
                                    LOGIN
                                </button>
                            </Level.Item>
                            <Level.Item className="topmenu signupBtn">
                                <button className="navMenu" onClick={props.openSignUp}>
                                    Doctor Sign up
                                </button>
                            </Level.Item>
                        </Fragment>
                    )
                }
                <Level.Item className="mobile">
                    <Logo />
                </Level.Item>
            </Level.Side>
            <Signup
                isOpen={props.signUpIsOpen}
                onClose={props.closeSignUp}
                onToLogin={() => {
                    toggleModal(TOGGLE_LOGIN);

                }}
                onSignUp={() => {
                    toggleModal(TOGGLE_SIGNUP_PROCEED);
                    props.closeSignUp();
                }}
            />
            <SignupProceed
                isOpen={isOpen(TOGGLE_SIGNUP_PROCEED)}
                onClose={() => toggleModal(TOGGLE_NONE)}
            />
            <Login
                isOpen={isOpen(TOGGLE_LOGIN)}
                openSignUp={props.openSignUp}
                onClose={() => toggleModal(TOGGLE_NONE)}
                onLogin={() => { toggleModal(TOGGLE_NONE) }}
                onToResetPassword={() => toggleModal(TOGGLE_RESET_PASSWORD)}
            />
            <ResetPassword
                isOpen={isOpen(TOGGLE_RESET_PASSWORD)}
                onClose={() => toggleModal(TOGGLE_NONE)}
                onResetPassword={() => toggleModal(TOGGLE_NONE)}
            />
        </Level>
    );
}

function mapDispatchToProps(state) {
    return {
        signUpIsOpen: state.ui.signUpIsOpen
    }
}

const bindActionCreator = { logout, closeSignUp, openSignUp };

export default withRouter(connect(mapDispatchToProps, bindActionCreator)(Navbar));
