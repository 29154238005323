import React, { Component } from 'react';
import ReactModal from 'react-modal';
import ForgotPasswordForm from './ForgotPasswordForm';


import './styles.scss';

ReactModal.setAppElement('#root');

class ResetPassword extends Component {

    render() {
        const { onClose, onResetPassword, isOpen } = this.props;

        return (
            <ReactModal
                isOpen={isOpen}
                className="modal-reset"
                overlayClassName="modal-overlay"
                onRequestClose={onClose}
            >
                <ForgotPasswordForm onResetPassword={onResetPassword} />
            </ReactModal>
        );
    }
}

export default ResetPassword;
