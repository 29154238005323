import React from 'react';
import ReactModal from 'react-modal';
import './styles.scss';

export default ({ isOpen, close, children }) => {
	return (
		<ReactModal
			className="partner-modal"
			overlayClassName="modal-overlay"
			isOpen={isOpen}
			onRequestClose={close}
		>
			<div role='button' className='close' onClick={() => { console.log(1231); close();}}>x</div>
			{children}
		</ReactModal>
	);
}
