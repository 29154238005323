import React, { useState, useEffect } from 'react';

export default function Slider({ slider, timeout = 2000 }) {
    const [slide, setSlide] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setSlide((second) => (second + 1) === slider.length ? 0 : (second + 1));
        }, timeout);

        return () => {
            clearInterval(interval);
        }
    }, [slider.length, timeout]);

    return <img src={slider[slide]} alt="" />;
}
