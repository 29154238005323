import React from 'react';
import { Image } from 'react-bulma-components';

import Checked from 'resources/images/checked.svg';
import './styles.scss';


export default () => {
	return (
		<div className='check-mark'>
			<Image src={Checked} />
		</div>
	)
}
