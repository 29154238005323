import React, { useEffect, useState } from 'react';
import { Columns, Container } from 'react-bulma-components';
import TestimonialsImage from 'resources/images/testimonials.svg';
import './styles.scss';

const testimonials = [
	{
		text: 'Hello, Just wanted to say thank you for this app! A mole on my husband’s head was labelled 100% concerning in your app, which prompted him to get it looked at. Turns out he had stage 1 melanoma. Thankfully he was able to get it removed before it spread anywhere. I have been spreading the word about Scanoma after a couple of friends and family members asked what made him get the mole checked. Thanks again, we are so grateful!!!',
		name: 'Happy User',
	},
	{
		text: 'This App Saves Lives!\nI went to a doctor and also dermatologist who both said I had nothing to worry about. This app and their doctor’s personal review of my photos gave me the confidence to get a biopsy and confirmed that I did in fact have something to worry about. Thank you so much for your incredible app.',
		name: 'l ***',
	},
	{
		text: 'The best skin cancer app out there\nThis app is fantastic for quick skin checks as well access to dermatologists. If you can\'t get an appointment fast where you are, this is a god send.',
		name: 'grev',
	},
	{
		text: 'Great service\nThis app was the first step in diagnosing a stage 1 melanoma on my back. A very helpful piece of tech, get your skin checked regularly people!',
		name: 'Mjrei4',
	},
];

const timeout = 5000;

export default () => {
	const [slide, selectSlide] = useState(0);
	
	useEffect(() => {
		const interval = setInterval(() => {
			selectSlide((slide) => (slide + 1) === testimonials.length ? 0 : (slide + 1));
		}, timeout);
		
		return () => clearInterval(interval);
	}, [selectSlide]);
	
	const testimonial = testimonials[slide];
	
	return (
		<Container id='testimonials'>
			<Columns>
				<Columns.Column className='image-wrapper'>
					<img src={TestimonialsImage} alt='testimonials' />
				</Columns.Column>
				<Columns.Column className='users'>
					<p className='title'>
						Patient <span className='blue font-italic'>Testimonials</span>
					</p>
					<p className='desc'>{testimonial.text}</p>
					<p className='name'>{testimonial.name}</p>
					<div className='buttons'>
						{testimonials.map((_, key) => (
							<button className={key === slide && 'active'} onClick={() => selectSlide(key)}>{key + 1}</button>
						))}
					</div>
				</Columns.Column>
			</Columns>
		</Container>
	);
}
