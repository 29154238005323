import React, { Component } from 'react';

const withOutSideRef = WrappedComponent => {
  return class extends Component {
    state = {
      toggle: false
    };

    componentDidMount() {
      document.addEventListener('mousedown', this.handleOutside);
    }

    componentWillUnmount() {
      document.removeEventListener('mousedown', this.handleOutside);
    }

    handleOutside = event => {
      if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
        this.setState({ toggle: false });
      }
    };

    setWrapperRef = node => {
      this.wrapperRef = node;
    };

    render() {
      return (
        <WrappedComponent
          {...this.props}
          toggle={this.state.toggle}
          wrapperRef={this.setWrapperRef}
        />
      );
    }
  };
};
export default withOutSideRef;
