import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Level } from 'react-bulma-components';
import Logo from '../../resources/images/logo.png';
import LogoSmall from '../../resources/images/logosmall.png';
import './styles.scss';

const Footer = () => {
    return (
        <Container id="footer">
            <Level>
                <Level.Side align="left">
                    <img alt="Logo" src={Logo} className="forpc" />
                    <img alt="LogoSmall" src={LogoSmall} className="mobile" />
                </Level.Side>
                <Level.Side align="right" className="footer-links">
                    <Link to="/blog">Blog</Link>
                    <Link to="/contact">Contact Us</Link>
                    <Link to="/terms-and-conditions">Term and conditions</Link>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                    <Link to="/press">Press</Link>
                </Level.Side>
            </Level>
        </Container>
    );
};

export default Footer;
