import React from 'react';
import classNames from 'classnames';

import './styles.scss';

// status: error/success
function FromInput (props) {
    const { multiple, select, small = true, placeholder, type, meta = {}, input = {}, label } = props;
    const inputClass = classNames({
        select,
        small,
        error: meta.error && meta.touched,
        success: meta.valid && !meta.pristine
    });

    return (
        <div className={classNames('form-input', { multiple })}>
            <label>{label}</label>
            {multiple ? (
                <textarea value={input.value} onChange={input.onChange} className={inputClass} placeholder={placeholder} />
            ) : (
                <input value={input.value} onChange={input.onChange} className={inputClass} type={type ? type : "text"} placeholder={placeholder} />
            )}
            {meta.error && meta.touched && <span className="message">{meta.error}</span>}
        </div>
    );
}

export default FromInput;
