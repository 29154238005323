import React, { Component } from 'react';
import ReactModal from 'react-modal';

import LineDecoration from 'components/Portal/LineDecoration';
import FormButton from 'components/Portal/FormButton';

import './styles.scss';
import imgSignupDone from 'resources/images/signup-success.png';

ReactModal.setAppElement('#root');

class SignupProceed extends Component {

    render() {
        const { onClose, isOpen } = this.props;

        return (
            <ReactModal
                isOpen={isOpen}
                className="modal-signedup"
                overlayClassName="modal-overlay"
                onRequestClose={onClose}
            >
                <div className="image">
                    <img src={imgSignupDone} alt="signup-done" />
                </div>
                <div className="title">
                    <h2>Thank you for signing up!</h2>
                    <LineDecoration primary small />
                </div>
                <div className="description">
                    <p>Please check your email for instructions on how to get start started !</p>
                </div>
                <div className="confirm">
                    <FormButton orange small action={onClose} >
                        Okay
                    </FormButton>
                </div>
            </ReactModal>
        );
    }
}

export default SignupProceed;
