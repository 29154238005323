import React from 'react';
import { Card, Image, Columns } from 'react-bulma-components';
import MicrosoftBadge from 'resources/images/MS_Startups_Celebration_Badge_Light.png'; // path to the Microsoft badge image
import FormButton from 'components/Portal/FormButton';
import useModal from 'utilities/useModal';
import CheckMark from 'components/CheckMark';
import PartnerModal from '../PartnerModal';

export default () => {
	const { isOpen, close, open } = useModal();

	return (
		<>
			<Card className='partner-card'>
				<Card.Content className='content-flex-grow'>
					<Image
						src={MicrosoftBadge}
						alt='Microsoft for Startups Badge'
					/>
					<p className='card-content-text'>
						Microsoft for Startups Founders Hub helps startups radically accelerate innovation by providing access to industry-leading AI services, expert guidance, and the essential technology needed to build a future-proofed startup.
					</p>


				</Card.Content>
				<div className='button-container'>
					<FormButton orange tiny action={open}>See Details</FormButton>
				</div>
			</Card>
			<PartnerModal
				isOpen={isOpen}
				close={close}
			>
				<p className='card-content-text'>
					Microsoft for Startups Founders Hub helps startups radically accelerate innovation by providing access to industry-leading AI services, expert guidance, and the essential technology needed to build a future-proofed startup.

				</p>



				<Columns>

				</Columns>
				<br />
				<a href="https://www.microsoft.com/en-us/startups/">Visit the Microsoft for startups website for more information</a>
			</PartnerModal>
		</>
	);
}
