import React from 'react';
import { useSelector } from 'react-redux';
import { Field, reduxForm, getFormValues } from 'redux-form';
import { FormInput, FormButton, FileInput } from 'components/Portal';
import imgUpload from 'resources/images/upload.png';
import imgUploaded from 'resources/images/uploaded.png';
import { email, required, minLength, shouldEqualToField, maxPasswordLength } from 'utilities/form';
import LineDecoration from 'components/Portal/LineDecoration';

const minLength4 = minLength(6);
const maxLength40 = maxPasswordLength(40);


const passwordMatch = shouldEqualToField('password', 'Passwords do not match');

function SignUpForm (props) {
    const { onToLogin, error, submitting, submitForm, handleSubmit, change } = props;
    const { file } = useSelector(state => getFormValues('signUpForm')(state)) || {};

    function removeFile() {
        change('file', '');
    }


    return (
        <form onSubmit={handleSubmit(submitForm)}>
            <div className="modal-header">
                <div className="text">
                    <h2>Sign Up</h2>
                    <LineDecoration primary small />
                </div>
                <div className="decorations">
                    <span className="times">&times;</span>
                    <span className="times">&times;</span>
                    <span className="times">&times;</span>
                    <span className="round" />
                    <span className="round" />
                    <span className="round" />
                </div>
            </div>
            <div className="modal-content">
                <div className="inputs">
                    <div className="left-panel">
                        <div className="left-panel__firstname">
                            <Field
                                name="firstName"
                                label="FIRST NAME"
                                placeholder="Your first name"
                                component={FormInput}
                                validate={required}
                            />
                        </div>
                        <div className="left-panel__lastname">
                            <Field
                                name="lastName"
                                label="LAST NAME"
                                placeholder="Your last name"
                                component={FormInput}
                                validate={required}
                            />
                        </div>
                        <div className="left-panel__sepcialization">
                            <Field
                                name="specialization"
                                label="SPECIALIZATION"
                                placeholder="Oncologist"
                                component={FormInput}
                                validate={required}
                            />
                        </div>
                        <Field
                            name="referredBy"
                            label="REFERRED BY"
                            placeholder="The person who referred you"
                            component={FormInput}
                        />
                    </div>
                    <div className="right-panel">
                        <Field
                            name="email"
                            label="EMAIL"
                            placeholder="Your email"
                            component={FormInput}
                            validate={[required, email]}
                        />
                        <Field
                            type="password"
                            name="password"
                            label="PASSWORD"
                            placeholder="Your password"
                            component={FormInput}
                            validate={[required, minLength4, maxLength40]}
                        />
                        <Field
                            type="password"
                            name="confirmPassword"
                            label="CONFIRM PASSWORD"
                            placeholder="Confirm your password"
                            component={FormInput}
                            validate={[required, passwordMatch, minLength4]}
                        />
                        <div className="upload">
                            <div className="upload-title">
                                <label>Medical license <span className="optional">(you may send it at a later time)</span></label>
                            </div>
                            <div className="upload-details">
                                <div className="upload-details__files">
                                    {file && (
                                        <div className="upload-details__files-image">
                                            <img src={imgUploaded} alt="uploaded" />
                                            <span className="remove" onClick={removeFile} />
                                        </div>
                                    )}
                                </div>
                                <div className="upload-details__push">
                                    <div className="upload-details__push-image">
                                        <img src={imgUpload} alt="upload" />
                                    </div>
                                    <div className="upload-details__push-text">
                                        <Field
                                            name='file'
                                            component={FileInput}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                { error && <span className='error_msg'>{error}</span> }
            </div>
            <div className="modal-footer">
                <div className="signup">
                    <FormButton orange small type='submit' disabled={submitting}>Sign Up</FormButton>
                </div>
                <div className="signin">
                    <span>Do you already have an account? <button type='submit' onClick={onToLogin}>Login</button></span>
                </div>
            </div>
        </form>
    );
}

export default reduxForm({
    form: 'signUpForm'
})(SignUpForm)
