import React from 'react';
import { Route, Redirect } from 'react-router';
import { useSelector } from 'react-redux';
import { loadState } from '../utilities';

const RedirectToRoot = () => <Redirect to="/" />

export default function PrivateRoute({ component: Component, fallback: Fallback = RedirectToRoot, ...rest }) {
    const token = useSelector(state => state.auth.token) || loadState()?.auth?.token;
    
    return (
        <Route
          {...rest}
          render={(props) => token ? <Component {...props} /> : <Fallback />}
        />
    )
}
