export function email (email) {
    return email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)
        ? 'Invalid email address'
        : undefined
}

export function required (value) {
    return (value || typeof value === 'number' ? undefined : 'Required');
}

export function minLength (min) {
    return (value) => value && value.length < min ? `Password must be at least ${min} characters long` : undefined;
}

export function maxPasswordLength (max) {
    return (value) => value && value.length > max ? `Oops. Password is too long. Maximum is ${max} characters` : undefined;
}

export function minTextLength (min) {
    return (value) => value && value.length < min ? `Please write a little bit more for your patient.` : undefined;
}

export function maxLength (max) {
    return (value) => value && value.length > max ? `Oops. Title is too long. Maximum is ${max} characters` : undefined;
}


export function shouldEqualToField (field, error) {
    return (value, values) => {
        return value && values[field] && value !==values[field] ? error : undefined;
    }
}
