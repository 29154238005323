import React from 'react';
import classNames from 'classnames';

import './styles.scss';

function FormSelect(props) {
    const { select, small = true, type, meta = {}, input = {}, options = {}, label } = props;
    const inputClass = classNames({
        select,
        small,
        error: meta.error && meta.touched,
        success: meta.valid && !meta.pristine
    });

    return (
        <div className={classNames('form-input', { select })}>
            <label>{label}</label>
            <select value={input.value} onChange={input.onChange} className={inputClass} type={type ? type : "select"}>
                {options.map(option => (
                    <option label={option.label} value={option.value} key={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
            {meta.error && meta.touched && <span className="message">{meta.error}</span>}
        </div>
    );
}

export default FormSelect;
