import { CLOSE_SIGN_UP, OPEN_SIGN_UP } from '../constants/ui';

export function closeSignUp () {
	return (dispatch) => {
		dispatch({
			type: CLOSE_SIGN_UP
		});
	};
}

export function openSignUp () {
	return (dispatch) => {
		dispatch({
			type: OPEN_SIGN_UP
		});
	};
}
