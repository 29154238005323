import React from 'react';
import ReactModal from 'react-modal';
import './styles.scss';
import { connect } from 'react-redux';
import { LineDecoration } from 'components/Portal';
import LoginForm from './LoginForm';
import { login } from 'actions/auth';

ReactModal.setAppElement('#root');

function Login(props) {
    const { onToResetPassword, onClose, isOpen, onLogin, login, openSignUp } = props;

    function submit(values) {
        return login(values).then(() => {
            onLogin();
        });
    }

    return (
        <ReactModal
            isOpen={isOpen}
            className="modal-login"
            overlayClassName="modal-overlay"
            onRequestClose={onClose}
        >
            <div className="modal-header">
                <div className="text">
                    <h2>Login</h2>
                    <LineDecoration small />
                </div>
                <div className="decorations">
                    <span className="times">&times;</span>
                    <span className="times">&times;</span>
                    <span className="times">&times;</span>
                    <span className="round" />
                    <span className="round" />
                    <span className="round" />
                </div>
            </div>
            <LoginForm onClose={onClose} openSignUp={openSignUp} submitForm={submit} onToResetPassword={onToResetPassword} />
        </ReactModal>
    );
}

export default connect(null, { login })(Login);
