import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';

//import reducers
import auth from './auth';
import requests from './requests';
import ui from './ui';
import blog from './blog';

const rootReducer = combineReducers({
	auth,
	requests,
	form,
	ui,
	blog,
});

export default rootReducer;
