export const GET_REQUESTS_SUCCESS = 'GET_REQUESTS_SUCCESS';
export const GET_REQUESTS_UNAUTHORIZED = 'GET_REQUESTS_UNAUTHORIZED';
export const CLEAR_REQUESTS = 'CLEAR_REQUESTS';


export const REPLY_TO_REQUEST_WITH_QUESTION_SUCCESS = 'REPLY_TO_REQUEST_WITH_QUESTION_SUCCESS'
export const REPLY_TO_REQUEST_WITH_QUESTION_FAILURE =  'REPLY_TO_REQUEST_WITH_QUESTION_FAILURE'

export const GET_HISTORY_SUCCESS = 'GET_HISTORY_SUCCESS';
export const RESET_REQUESTS = 'RESET_REQUESTS';
export const RESET_HISTORY = 'RESET_HISTORY';

export const GET_PATIENT_HISTORY_REQUESTS_SUCCESS = 'GET_PATIENT_HISTORY_REQUESTS_SUCCESS';
export const GET_PATIENT_HISTORY_REQUESTS_FAILURE = 'GET_PATIENT_HISTORY_REQUESTS_FAILURE';
export const GET_PATIENT_HISTORY_REQUESTS_PENDING = 'GET_PATIENT_HISTORY_REQUESTS_PENDING';
export const PATIENT_HISTORY_RESET = 'PATIENT_HISTORY_RESET';
