import React from 'react';
import Loadable from 'react-loadable';
import { Redirect, Route } from 'react-router-dom';
import './MyApp.scss';

import Home from './pages/Home';
import PrivateRoute from './components/PrivateRoute';
import { Helmet } from 'react-helmet';

const Loading = () => <div>Loading...</div>;

const ContactUs = Loadable({
	loader: () => import(/* webpackChunkName: "myNamedChunk" */ './pages/ContactUs'),
	loading: Loading,
	modules: ['ContactUs'],
});

const Profile = Loadable({
	loader: () => import(/* webpackChunkName: "myNamedChunk" */ './pages/Profile'),
	loading: Loading,
	modules: ['Profile'],
});

const Requests = Loadable({
	loader: () => import(/* webpackChunkName: "myNamedChunk" */ './pages/Requests'),
	loading: Loading,
	modules: ['Requests'],
});

const Blog = Loadable({
	loader: () => import(/* webpackChunkName: "myNamedChunk" */ './pages/Blog'),
	loading: Loading,
	modules: ['Blog'],
});

const LandingPage = Loadable({
	loader: () => import(/* webpackChunkName: "myNamedChunk" */ './pages/LandingPage'),
	loading: Loading,
	modules: ['LandingPage'],
});

const TermsAndConditions = Loadable({
	loader: () => import(/* webpackChunkName: "myNamedChunk" */ './pages/TermsAndConditions'),
	loading: Loading,
	modules: ['TermsAndConditions'],
});

const Article = Loadable({
	loader: () => import(/* webpackChunkName: "myNamedChunk" */ './pages/Blog/Article'),
	loading: Loading,
	modules: ['LandingPage'],
});

const PrivacyPolicy = Loadable({
	loader: () => import(/* webpackChunkName: "myNamedChunk" */ './pages/PrivacyPolicy'),
	loading: Loading,
	modules: ['PrivacyPolicy'],
});


const RefundPolicy = Loadable({
	loader: () => import(/* webpackChunkName: "myNamedChunk" */ './pages/RefundPolicy'),
	loading: Loading,
	modules: ['RefundPolicy'],
});

const EmailConfirmed = Loadable({
	loader: () => import(/* webpackChunkName: "myNamedChunk" */ './pages/EmailConfirmed'),
	loading: Loading,
	modules: ['EmailConfirmed'],
});

const EmailAlreadyConfirmed = Loadable({
	loader: () => import(/* webpackChunkName: "myNamedChunk" */ './pages/EmailAlreadyConfirmed'),
	loading: Loading,
	modules: ['EmailAlreadyConfirmed'],
});


const EmailConfirmationError = Loadable({
	loader: () => import(/* webpackChunkName: "myNamedChunk" */ './pages/EmailConfirmationError'),
	loading: Loading,
	modules: ['EmailConfirmationError'],
});



const Definitions = Loadable({
	loader: () => import(/* webpackChunkName: "myNamedChunk" */ './pages/Definitions'),
	loading: Loading,
	modules: ['Definitions'],
});


const PasswordRestore = Loadable({
	loader: () => import(/* webpackChunkName: "myNamedChunk" */ './pages/PasswordRestore'),
	loading: Loading,
	modules: ['PasswordRestore'],
});

const Press = Loadable({
	loader: () => import(/* webpackChunkName: "myNamedChunk" */ './pages/Press'),
	loading: Loading,
	modules: ['Press'],
});


const RedirectToRequests = () => <Redirect to='/requests' />;

function App() {
	return (
		<>
			<Helmet>
				<title>scanoma · check moles for skin cancer and consult with dermatologists</title>
				<meta name="keywords" content="skin cancer, health, diagnosis, dermatology, doctors, health scan, mole, moles, app" />
				<meta name="description" content="Scanoma is an app that checks your moles, calculates your skin cancer risk and connects you with dermatologists for a medical diagnosis." />
				<meta name="google-site-verification" content="X5r3f7wBQGAlbvB_IKvZNEGCaduZ8Zsj44UEhTls37E" />
			</Helmet>

			<PrivateRoute exact path='/' component={RedirectToRequests} fallback={Home} />
			<Route exact path='/contact' component={ContactUs} />
			<PrivateRoute exact path='/profile' component={Profile} />
			<PrivateRoute exact path='/requests/:requestId?' component={Requests} />
			<Route exact path='/blog' component={Blog} />
			<Route exact path='/download-app' component={LandingPage} />
			<Route exact path='/blog/:slug' component={Article} />
			<Route exact path='/password-restore/:token' component={PasswordRestore} />
			<Route exact path='/terms-and-conditions' component={TermsAndConditions} />
			<Route exact path='/privacy-policy' component={PrivacyPolicy} />
			<Route exact path='/refund-policy' component={RefundPolicy} />
			<Route exact path='/email-confirmed' component={EmailConfirmed} />
			<Route exact path='/email-already-confirmed' component={EmailAlreadyConfirmed} />
			<Route exact path='/email-confirmation-error' component={EmailConfirmationError} />
			<Route exact path='/skin-diagnosis-definitions' component={Definitions} />
			<Route exact path='/press' component={Press} />
		</>
	);
}

export default App;
