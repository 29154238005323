import React from 'react';
import { Card, Media, Image, Columns } from 'react-bulma-components';
import CuriaLogo from 'resources/images/curia-logo-round.png';
import CuriaApp1 from 'resources/images/curia-app-1.jpeg';
import CuriaApp2 from 'resources/images/curia-app-2.jpeg';
import FormButton from 'components/Portal/FormButton';
import useModal from 'utilities/useModal';
import CheckMark from 'components/CheckMark';
import PartnerModal from '../PartnerModal';

const CuraTM = () => (
	<Media>
		<Image
			className="media-logo"
			alt="The CURA™ app logo"
			src={CuriaLogo}
			style={{ width: '48px', height: '48px' }} // Set fixed width and height as needed
		/>
		<div className='card-heading-title'>The CURA&trade; app</div>
	</Media>
)

export default () => {
	const { isOpen, close, open } = useModal();

	return (
		<>
			<Card className='partner-card'>
				<Card.Content className='content-flex-grow'>
					<CuraTM />
					<p className='card-content-text'>
						Provides cancer patients with accurate and current information about treatments, trials and experts,
						tailored to their specific cancer type. AI technology, supported by a human care-team, is used to keep
						information in the app up-to-date and ensure results are tailored to the user’s …
					</p>

				</Card.Content>
				<div className='button-container'>
					<FormButton orange tiny action={open}>See Details</FormButton>
				</div>
			</Card>
			<PartnerModal
				isOpen={isOpen}
				close={close}
			>
				<CuraTM />
				<p className='card-content-text'>
					The CURIA™ app provides cancer patients with accurate and current information about
					treatments, trials and experts, tailored to their specific cancer type. AI technology,
					supported by a human care-team, is used to keep information in the app up-to-date and
					ensure results are tailored to the user’s cancer profile. The app seeks to address the alarming
					statistic that as many as 70% of those who have had cancer report being dissatisfied
					with the information they receive.
				</p>
				<p className='card-content-text'>
					The CURIA™ App is powered by blockchain technology to ensure anonymity and protection with the best security features.
				</p>
				<p className='card-content-text'>
					From the app, users can utilize services like connecting and communicating with a “Cancer Twin”, accessing other digital therapeutic apps and also connect with other cancer resources like Scanoma.
				</p>


				<Columns>
					<Columns.Column className='benefits'>
						<CheckMark />
						<p>Over 800,000 cancer patients globally have benefited from the CURIA™ App.</p>
					</Columns.Column>
					<Columns.Column className='benefits'>
						<CheckMark />
						<p>Over 3,000 new patients are joining daily from around the world.</p>
					</Columns.Column>
				</Columns>
				<Columns>
					<Columns.Column>
						<Image
							src={CuriaApp1}
						/>
					</Columns.Column>
					<Columns.Column>
						<Image
							src={CuriaApp2}
						/>

					</Columns.Column>

				</Columns>
				<br />
				<a href="https://us.curia.app/">Visit the CURA™ website for more information</a>
			</PartnerModal>
		</>
	);
}
