import jwt from 'jwt-decode';
import { SIGN_UP_REQUEST_SUCCESS, LOG_OUT, SET_AVATAR } from '../constants/auth';
import { saveState, clearState } from 'utilities/localStorage';

const initialState = {
	firstName: '',
	lastName: '',
	token: '',
	expire: '',
	avatar: '',
	id: '',
};

export default function auth (state = initialState, action = {}) {
	const { type, payload } = action;
	
	switch (type) {
	case SIGN_UP_REQUEST_SUCCESS: {
		const token = jwt(payload.token);
		const userState = {
			firstName: payload.firstName,
			lastName: payload.lastName,
			token: payload.token,
			expire: token.exp,
			avatar: payload.avatar,
			id: token._id,
		};
		saveState({ auth: userState });
		
		return {
			...state,
			...userState,
		};
	}
	
	case SET_AVATAR:
		const userState = { ...state, avatar: payload };
		saveState({ auth: userState });
		return userState;
	
	case LOG_OUT: {
		clearState();
		
		return {
			initialState
		};
	}
	
	default:
		return state;
	}
}
