import React from 'react';
import classNames from 'classnames';
import './styles.scss';

const FormButton = ({ primary, orange, small, tiny, dark, action, children, type, disabled, right }) => {
    const btnClass = classNames('form-button', { primary, orange, small, tiny, dark, right });
    return (
        <button
            type={type}
            onClick={() => {
                action && action();
            }}
            disabled={disabled}
            className={btnClass}
        >
            {children}
        </button>
    );
};

export default FormButton;
