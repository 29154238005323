import React from 'react';
import { connect } from 'react-redux';
import ReactModal from 'react-modal';
import SignUpForm from './SignUpForm';
import { register } from 'actions/auth';


import './styles.scss';

ReactModal.setAppElement('#root');

function Signup(props) {
    const { onToLogin, onClose, isOpen, register, onSignUp } = props;

    function signUp(values) {
        const data = { ...values, type: 'doctor' };
        return register(data).then(() => {
            onSignUp();
        });
    }

    return (
        <ReactModal
            isOpen={isOpen}
            className="modal-signup"
            overlayClassName="modal-overlay"
            onRequestClose={onClose}
        >
            <SignUpForm submitForm={signUp} onToLogin={onToLogin} />
        </ReactModal>
    );
}

export default connect(undefined, { register })(Signup);
