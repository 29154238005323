import React, { useState } from 'react';
import { reduxForm, Field } from 'redux-form';
import LineDecoration from 'components/Portal/LineDecoration';
import FormInput from 'components/Portal/FormInput';
import FormButton from 'components/Portal/FormButton';
import { forgotPassword } from 'actions/auth';
import { required, email } from 'utilities/form';

function PasswordRestoreForm(props) {
    const { error, submitting, handleSubmit, reset, onResetPassword } = props;
    const [success, setSuccess] = useState(null);

    function onChange (value) {
        return forgotPassword(value)
            .then(() => {
                setSuccess('Follow the instructions sent to your email');
                setTimeout(() => {
                    reset();
                    setSuccess(null);
                    onResetPassword()
                }, 4000);
            })
    }

    return (
        <form onSubmit={handleSubmit(onChange)}>
            <div className="modal-header">
                <div className="text">
                    <h2>Reset password</h2>
                    <LineDecoration />
                </div>
                <div className="decorations">
                    <span className="times">&times;</span>
                    <span className="times">&times;</span>
                    <span className="round" />
                    <span className="round" />
                </div>
            </div>
            <div className="modal-content">
                <div className="description">
                    <p>Enter the email address associated with your account.</p>
                </div>
                <div className="email">
                    <Field
                        name='email'
                        label='EMAIL'
                        placeholder="Your email"
                        component={FormInput}
                        validate={[required, email]}
                    />
                </div>
            </div>
            { error && <span className='error_msg'>{error}</span> }
            { success && <div className='success_msg'>{success}</div> }
            <div className="modal-footer">
                <div className="login">
                    <FormButton disabled={submitting} primary type='submit'>Send</FormButton>
                </div>
            </div>
        </form>
    );
}

export default reduxForm({
    form: 'password-change',
})(PasswordRestoreForm);
