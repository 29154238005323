export { saveState, loadState } from './localStorage';

export function fileToBase64(file) {
    return new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = function () {
            resolve(reader.result);
        };
    });
}
