import React from 'react';
import { Field, reduxForm } from 'redux-form'
import { FormInput, FormButton } from 'components/Portal';
import { email, required, minLength } from 'utilities/form';

const minLengthPass = minLength(4);

function LoginForm (props) {
    const { handleSubmit, submitting, error, submitForm, onToResetPassword, onClose, openSignUp } = props;

    function sighUp() {
        onClose();
        openSignUp();
    }

    return (
        <form onSubmit={handleSubmit(submitForm)}>
            <div className="modal-content">
                <div className="inputs">
                    <div className="input-email">
                        <Field
                            name="email"
                            label="EMAIL"
                            placeholder="Your email"
                            component={FormInput}
                            validate={[email, required]}
                        />
                    </div>
                    <div className="input-password">
                        <button type='button' className="input-password__forgot" onClick={onToResetPassword}>
                            Forgot your password?
                        </button>
                        <Field
                            name="password"
                            label="PASSWORD"
                            type="password"
                            placeholder="Your password"
                            component={FormInput}
                            validate={[required, minLengthPass]}
                        />
                    </div>
                </div>
            </div>
            { error && <span className='error_msg'>{error}</span> }
            <div className="modal-footer">
                <div className="login">
                    <FormButton primary type='submit' disabled={submitting}>
                        Login
                    </FormButton>
                </div>
                <div className="already">
                    <span>You don't have account?</span>
                    <button onClick={sighUp} type='button'>Sign up here !</button>
                </div>
            </div>
        </form>
    )
}


export default reduxForm({
    form: 'loginForm'
})(LoginForm)
