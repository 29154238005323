import { OPEN_SIGN_UP, CLOSE_SIGN_UP } from '../constants/ui';

const initialState = {
    signUpIsOpen: false,
};

export default function ui(state = initialState, action = {}) {
    const { type } = action;

    switch (type) {
    case CLOSE_SIGN_UP:
        return { signUpIsOpen: false };

    case OPEN_SIGN_UP:
        return { signUpIsOpen: true };

    default:
        return state;
    }
}
