import React from 'react';
import classNames from 'classnames';

import './styles.scss';

const LineDecoration = ({ primary, small }) => {
  const lineClass = classNames('lines', {
    orange: primary,
    small: small
  });
  return (
    <div className={lineClass}>
      <span className="line-long" />
      <span className="line-short" />
    </div>
  );
};

export default LineDecoration;
