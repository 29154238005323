import thunk from 'redux-thunk';
import { applyMiddleware, compose, createStore } from 'redux';
import { loadState } from '../utilities';
import rootReducer from '../reducers';

const persistedState = loadState();

const middleware = [
	thunk,
];

const enhancers = [
	applyMiddleware(...middleware), 
];

const composeEnhancers =
	process.env.NODE_ENV !== 'production' &&
	typeof window === 'object' &&
	window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
		window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;

export default function configureStore(initialState = {}) {
	return createStore(
		rootReducer,
		{ ...persistedState, ...initialState},
		composeEnhancers(...enhancers),
	);
};
