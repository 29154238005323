import {
    RESET_HISTORY,
    RESET_REQUESTS,
    GET_REQUESTS_SUCCESS,
    GET_REQUESTS_UNAUTHORIZED,
    REPLY_TO_REQUEST_WITH_QUESTION_SUCCESS,
    GET_HISTORY_SUCCESS,
    CLEAR_REQUESTS,
    GET_PATIENT_HISTORY_REQUESTS_SUCCESS,
    GET_PATIENT_HISTORY_REQUESTS_FAILURE,
    PATIENT_HISTORY_RESET,
} from '../constants/requests';

const initialState = {
    requests: {
        list: [],
        fetching: false,
    },
    history: { //docotr history
        list: [],
        fetching: false,
        historyCountthisMonth: 0,
    },
    patientHistory: { //patient history
        list: [],
        fetching: false,
    },
};

export default function requests(state = initialState, action = {}) {
    const { type, payload, historyCountCurrentMonth } = action;

    switch (type) {
        case GET_REQUESTS_SUCCESS: {
            return {
                ...state,
                requests: { ...state.requests, list: payload, fetching: true },
            };
        }

        case GET_REQUESTS_UNAUTHORIZED: {
            return {
                ...state,
                requests: { ...state.requests, list: payload, fetching: true, unauthorized: true },
            };
        }

        case GET_HISTORY_SUCCESS: {
            return {
                ...state,
                history: { ...state.history, list: payload, historyCountCurrentMonth: historyCountCurrentMonth, fetching: true },
            };
        }



        
        case REPLY_TO_REQUEST_WITH_QUESTION_SUCCESS: {


            return {
                ...state,
             
                requests: {
                    ...state.requests,   fetching: true,
                    ...state.requests.request,
                    list: state.requests.list.map(req => {
                        if (req._id !== payload.id) {
                            return req;
                        }
                        else {
                            return {
                                ...req,
                                complete: true,
                                hasPendingQuestion: false
                            }
                        }

                    })
                }

            }
        }

        
        case RESET_REQUESTS:
            return { ...state, requests: { ...state.requests, fetching: false } };

        case RESET_HISTORY:
            return { ...state, history: { ...state.history, fetching: false } };

        case CLEAR_REQUESTS:
            return initialState;


        //Specific to a to a patient  //TODO: should we move this to its own reducer?
        case GET_PATIENT_HISTORY_REQUESTS_SUCCESS: {
            return {
                ...state, patientHistory: { ...state.patientHistory, list: payload, fetching: true },
            };
        }

        case PATIENT_HISTORY_RESET: {
            return {
                ...state, patientHistory: { ...state.patientHistory, list: [], fetching: false },
            };
        }

        case GET_PATIENT_HISTORY_REQUESTS_FAILURE: {
            return {
                ...state, patientHistory: { ...state.patientHistory, list: payload, fetching: true },
            };
        }

        default:
            return state;
    }
}
