import React from 'react'
import LogoImage from 'resources/images/logo.png';
import { withRouter } from 'react-router';

function Logo({ history }) {
    function goHome() {
        history.push('/')
    }

    return <img alt="logo" src={LogoImage} onClick={goHome} />;
}

export default withRouter(Logo);
